import { faExternalLinkAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import BackgroundImage from 'gatsby-background-image';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

export default function Enterprise({ location, data, pathContext: { locale, pageSlugs } }) {
  const { markdownFileText: { frontmatter } } = data;

  return (
    <Layout location={location} locale={locale} pageSlugs={pageSlugs}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        language={locale}
      />
      <BackgroundImage
        tag="div"
        className="d-table w-100 h-175-px"
        fluid={data.datArtAboutUsHeaderImage.childImageSharp.fluid}
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            {frontmatter.mainTitle}
          </h1>
        </div>
      </BackgroundImage>
      <Container className="p-4">
        <p>{frontmatter.introText}</p>
      </Container>
      <div className="bg-grey">
        <Container className="p-4">
          {/* <h1 className="text-info font-weight-600"> */}
          {/*  Présentation DatArt */}
          {/* </h1> */}
          <Row>
            <Col md={7} className="my-auto">
              <h2 className="left-border pl-2">{frontmatter.newCollaboratorsTitle}</h2>
              <p className="lead">{frontmatter.newCollaboratorsText}</p>
            </Col>
            <Col md={5} className="my-auto">
              <Img
                fluid={data.datArtNewEmployeesIcon.childImageSharp.fluid}
                className="rounded w-75 mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-white">
        <Container className="px-4 py-5">
          <Row>
            <Col
              md={{
                span: 7,
                order: 2,
              }}
              className="my-auto"
            >
              <h2 className="left-border pl-2">{frontmatter.wagePolicyTitle}</h2>
              <p className="lead">{frontmatter.wagePolicyText}</p>
            </Col>
            <Col
              md={{
                span: 5,
                order: 1,
              }}
              className="my-auto"
            >
              <Img
                fluid={data.datArtSalariesIcon.childImageSharp.fluid}
                className="rounded w-40 mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-grey">
        <Container className="p-4">
          {/* <h1 className="text-info font-weight-600"> */}
          {/*  Présentation DatArt */}
          {/* </h1> */}
          <Row>
            <Col md={7} className="my-auto">
              <h2 className="left-border pl-2">{frontmatter.transparencyTitle}</h2>
              <p className="lead">{frontmatter.transparencyText}</p>
            </Col>
            <Col md={5} className="my-auto">
              <Img
                fluid={data.datArtTransparencyIcon.childImageSharp.fluid}
                className="rounded w-40 mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-white">
        <Container className="px-4 py-5">
          <Row>
            <Col
              md={{
                span: 7,
                order: 2,
              }}
              className="my-auto"
            >
              <h2 className="left-border pl-2">{frontmatter.friendlyAtmosphereTitle}</h2>
              <p className="lead">{frontmatter.friendlyAtmosphereText}</p>
            </Col>
            <Col
              md={{
                span: 5,
                order: 1,
              }}
              className="my-auto"
            >
              <Img
                fluid={data.datArtConvivialityIcon.childImageSharp.fluid}
                className="rounded w-60 mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-grey">
        <Container className="px-4 py-5">
          {/* <h1 className="text-info font-weight-600"> */}
          {/*  Présentation DatArt */}
          {/* </h1> */}
          <Row>
            <Col md={7} className="my-auto">
              <h2 className="left-border pl-2">{frontmatter.informationExchangeTitle}</h2>
              <p className="lead">{frontmatter.informationExchangeText}</p>
            </Col>
            <Col md={5} className="my-auto">
              <Img
                fluid={data.datArtFormationIcon.childImageSharp.fluid}
                className="rounded w-85 mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-white">
        <Container className="px-4 py-5">
          <Row>
            <Col
              md={{
                span: 7,
                order: 2,
              }}
              className="my-auto"
            >
              <h2 className="left-border pl-2">{frontmatter.responsibilityTitle}</h2>
              <p className="lead">{frontmatter.responsibilityText}</p>
            </Col>
            <Col
              md={{
                span: 5,
                order: 1,
              }}
              className="my-auto"
            >
              <Img
                fluid={data.datArtAutonomyIcon.childImageSharp.fluid}
                className="rounded w-55 mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-grey">
        <Container className="p-4">
          <Row>
            <Col md={7} className="my-auto">
              <h2 className="left-border pl-2">{frontmatter.sponsoringTitle}</h2>
              <p className="lead">{frontmatter.sponsoringText}</p>
            </Col>
            <Col md={5} className="my-auto">
              <Img
                fluid={data.datArtSponsoringImage.childImageSharp.fluid}
                className="rounded w-75 mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

Enterprise.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`  
    fragment pagesHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    fragment iconFluidImage on File {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "about-us-business-culture"}}) {
            frontmatter {
                title
                description

                mainTitle

                introText
                
                newCollaboratorsTitle
                newCollaboratorsText
                wagePolicyTitle
                wagePolicyText
                transparencyTitle
                transparencyText
                friendlyAtmosphereTitle
                friendlyAtmosphereText
                informationExchangeTitle
                informationExchangeText
                responsibilityTitle
                responsibilityText
                sponsoringTitle
                sponsoringText
            }
        }
        
        datArtAboutUsHeaderImage: file(relativePath: {regex: "/datArtAboutUsHeaderImage/"}) {
            ...pagesHeaderFluidImage
        }

        datArtNewEmployeesIcon: file(relativePath: {regex: "/datArtNewEmployeesIcon/"}) {
            ...iconFluidImage
        }

        datArtSalariesIcon: file(relativePath: {regex: "/datArtSalariesIcon/"}) {
            ...iconFluidImage
        }
        
        datArtTransparencyIcon: file(relativePath: {regex: "/datArtTransparencyIcon/"}) {
            ...iconFluidImage
        }
        
        datArtConvivialityIcon: file(relativePath: {regex: "/datArtConvivialityIcon/"}) {
            ...iconFluidImage
        }
        
        datArtFormationIcon: file(relativePath: {regex: "/datArtFormationIcon/"}) {
            ...iconFluidImage
        }
        
        datArtAutonomyIcon: file(relativePath: {regex: "/datArtAutonomyIcon/"}) {
            ...iconFluidImage
        }

        datArtSponsoringImage: file(relativePath: {regex: "/datArtSponsoringImage/"}) {
            ...iconFluidImage
        }
    }
`;
